import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSunriseContext } from "../data/context";
import { UseAvatarKey } from "../utils/useAvtarKey";
import { getavatar, getResponse } from "../service/avatarService";
import Loader from "../components/loader";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRecordVinyl,
  faMicrophone,
  faVolumeHigh,
  faVolumeXmark,
  faPaperPlane,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";
import "./avatar.css";

const Avatar = () => {
  const [speechState, setSpeechState] = useState("idle");
  const { state, dispatch } = useSunriseContext();
  const { avatar, loaded } = state;
  const [isRecording, setIsRecording] = useState(false);
  const [isMuted, setmute] = useState(false);
  const [showChat, setShowChat] = useState(true);
  let [chatArray, setChatArray] = useState([]);
  const [inputText, setInputText] = useState("");
  const audioRef = useRef("");
  let newAudioSource = "";
  const [showChats, setShowChats] = useState(false);
  const [showChatBar, setShowChatBar] = useState(true);
  const [currentWord, setCurrentWord] = useState("");
  const [currentSentence, setCurrentSentence] = useState("");
  const avatarkey = UseAvatarKey();
  const [chat, setChat] = useState("");
  const getmovementStartTime = useCallback(() => {
    if (loaded) {
      let arr = JSON.parse(avatar.movementStartTime);
      let movementStartTime = arr.map((textNumber) => textNumber * 1);
      return movementStartTime;
    }
  }, [loaded, avatar]);

  const gettingavatar = useCallback(async () => {
    try {
      const avatarData = await getavatar(avatarkey);
      if (avatarData) {
        dispatch({ type: "SET_avatar", payload: avatarData.data.data });
      }
    } catch (error) {
      console.log("Some error occurred");
    }
  }, [avatarkey]);

  const handleToggleMute = () => {
    // Toggle the mute state
    setmute((prevmute) => !prevmute);

    // Toggle the audio mute state
    if (audioRef.current) {
      audioRef.current.muted = !isMuted;
    }
  };

  useEffect(() => {
    if (loaded) return;
    gettingavatar();
  }, [gettingavatar, loaded]);

  const localVideoRef = useRef();

  function useInterval(callback, delay) {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    if (avatar.animation === "Custom") {
      if (speechState === "idle") {
        if (
          localVideoRef.current.children[0].currentTime > avatar.idleEndTime
        ) {
          localVideoRef.current.children[0].currentTime = avatar.idleStartTime;
        }
      } else {
        if (
          localVideoRef.current.children[0].currentTime <= avatar.idleEndTime
        ) {
          const randomIndex = Math.floor(
            Math.random() * getmovementStartTime().length
          );
          const randomTime = getmovementStartTime()[randomIndex];
          localVideoRef.current.children[0].currentTime = randomTime;
        }
      }
    }
  }, 1000);

  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && localVideoRef.current) {
      // obtain reference to the video element
      const player = localVideoRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                localVideoRef.current.style.display = "none";
                avatar.animation = "Default";
              });
          }
        }, 0);
      }
    }
  }, []);

  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
  };

  useEffect(() => {
    const favIcon = document.querySelector("#fav-icon");
    const title = document.querySelector("#title");

    favIcon.href = avatar.logo;
    title.innerText = avatar.key;

    if (avatar.animation === "Custom") {
      localVideoRef.current.src = avatar.video;
    } else {
      clearInterval();
    }
    if (avatar.isMuted) {
      handleToggleMute();
    }
    if (avatar.isMuted) {
      apiResponse("Hi");
    }
  }, [loaded]);
  async function modalButtonHandler() {
    playAudio();
    document.querySelector("#popupModal").style.display = "none";
    await apiResponse("Hi");
  }
  const {
    transcript,
    resetTranscript,
    finalTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    toast.error("Browser doesn't suppert voice recognition");
  }
  const stopListnening = () => {
    SpeechRecognition.stopListening();
    resetTranscript();
  };
  const voice = () => {
    setChatArray((prev) => [...prev, { action: "user", text: transcript }]);
    apiResponse(transcript);
    setChat(transcript);
  };
  useEffect(() => {
    if (transcript !== "") {
      const words = transcript.split(" ");
      const latestWord = words[words.length - 1];
      setCurrentWord(latestWord);
      // Update the sentence with the entire transcript
      setCurrentSentence(transcript);
      // Show the sentence and update word by word
      setShowChats(true);
      // Reset the sentence after a certain duration (adjust the duration as needed)
      const timeoutId = setTimeout(() => {
        setShowChats(false);
        setCurrentSentence("");
        setChat("");
      }, 6000);
    }
  }, [transcript]);
  useEffect(() => {
    // Update the chat with the current sentence
    if (currentSentence !== "") {
      setChat(currentSentence);
    }
    // Add any additional logic you need based on the current word or sentence
  }, [currentWord, currentSentence]);
  function chatScroll() {
    const chat = document.querySelector(".chat");
    setTimeout(() => {
      chat.scrollTo(0, chat.scrollHeight);
    }, 100);
  }
  useEffect(() => {
    if (finalTranscript !== "") {
      console.log("Got final result:", finalTranscript);
      voice();
      resetTranscript();
    }
  }, [finalTranscript]);
  const apiResponse = (text) => {
    let body = {
      languageCode: avatar.language,
      queryText: text,
      sessionId: "abc",
      PROJECID: avatar.projectId,
      private_key: avatar.privateKey,
      client_email: avatar.clientEmail,
      voice_name: avatar.voiceName,
    };
    getResponse(body)
      .then((res) => {
        // Handle the data in the 'res' variable
        if (res) {
          setChatArray((prev) => [
            ...prev,
            { action: "chatBot", text: res.text },
          ]);
          chatScroll();
          let audio = JSON.parse(res.audioBlob);
          const audioData = new Uint8Array(audio.data);
          const blob = new Blob([audioData], { type: "audio/mp3" });

          // Set the source of the audio element
          audioRef.current.src = URL.createObjectURL(blob);

          // Ensure that the MIME type is set correctly
          audioRef.current.type = "audio/mp3";
          setSpeechState("speaking");
          stopListnening();
          // Play the audio
          audioRef.current.play();

          if (chatArray.length === 0) {
            audioRef.current?.addEventListener("ended", () => {
              setSpeechState("idle");
              if (!isRecording && avatar.key !== "TASIS") {
                startListening();
              }
            });
          }
          return;
        }
      })
      .catch((error) => {
        // Handle errors if the promise is rejected
        console.error("Error:", error);
      });
  };

  const startListening = () => {
    setIsRecording(true);
    SpeechRecognition.startListening({
      continuous: true,
      language: avatar.language,
    });
  };

  useEffect(() => {
    if (isRecording) {
      startListening();
    } else {
      stopListnening();
    }
  }, [isRecording]);

  //Play audio on start button
  const playAudio = () => {
    if (audioRef.current.src === "") {
      audioRef.current?.play();
    }
  };

  // toggle mic buttons
  const handleSpeakClick = async () => {
    const changeRecording = !isRecording;
    setIsRecording(changeRecording);
  };

  // handle the entered text
  const handleTextInput = () => {
    if (inputText === "") {
      toast.warning("PLease Speak Clearly");
      return;
    }
    setChatArray((prev) => [...prev, { action: "user", text: inputText }]);
    chatScroll();
    setInputText("");
    apiResponse(inputText);
  };

  if (!loaded) return <Loader />;
  else
    return (
      <div
        className="flex flex-col h-screen relative bg-[black] screen"
        style={
          avatar.animation === "Default"
            ? {
                backgroundColor: `${avatar.backgroundColor}`,
              }
            : {
                backgroundColor: "black",
              }
        }
      >
        {(avatar.key === "nhchayat" ||
          avatar.key === "nhc" ||
          !avatar.isMuted) && (
          <div id="popupModal" className="modal">
            <div className="modal-content">
              <video>
                <source src={avatar.video} type="video/mp4" />
              </video>
              <button id="modalButton" onClick={modalButtonHandler}>
                {avatar.key === "nhchayat" || avatar.key === "nhc"
                  ? "ابدأ التفاعل"
                  : "Start Interaction"}
              </button>
            </div>
          </div>
        )}

        {avatar.animation === "Default" && (
          <div
            className="absolute top-0 left-0 right-0 p-[8px] z-[222] "
            style={{ backgroundColor: `${avatar.characterColor}` }}
          >
            <div className="flex items-center">
              <a
                target="_blank"
                href={
                  avatar.key !== "TASIS" ||
                  (avatar.key !== "TASISDEMO" &&
                    "https://www.tasisengland.org/")
                }
              >
                {avatar.logo && (
                  <img
                    className="header-logo w-[50px]"
                    src={avatar.logo}
                    alt="Logo"
                  />
                )}
              </a>
              <div
                className="header-text grow text-white text-center font-bold text-[30px]"
                style={{ color: `${avatar.textColor}` }}
              >
                {avatar.text}
              </div>
            </div>
          </div>
        )}
        <div className="w-[70%] flex flex-1 justify-center background-wrapper">
          {avatar.animation === "Default" ? (
            <div></div>
          ) : (
            <div
              className="flex video-tag"
              ref={localVideoRef}
              dangerouslySetInnerHTML={{
                __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          preload="metadata"
        >
        <source src="${avatar.video}" type="video/mp4" />
        </video>`,
              }}
            />
          )}
        </div>
        <audio ref={audioRef}>
          <source src={newAudioSource} type="audio/mp3" />
        </audio>

        <div className="flex h-[100%] flex-col-reverse">
          {avatar.animation === "Default" ? (
            <div className="chat w-[100%] z-[1000]">
              {chatArray
                .slice(avatar.chat_history ? 0 : -2)
                .map((message, index) => (
                  <div
                    key={index}
                    className={
                      message.action === "user"
                        ? "flex justify-end items-center"
                        : "flex justify-start items-center"
                    }
                  >
                    {
                      message.action !== "user" ? (
                        <div className="w-[50px] mr-[10px] mb-[1rem]">
                          {avatar.key !== "TASIS" ||
                          avatar.key !== "TASISDEMO" ? (
                            <img
                              src="https://sunrisedatabucket.s3.amazonaws.com/inworld/Royals_Logo__1_-removebg-preview-1708419396552.png"
                              alt="Logo"
                            />
                          ) : (
                            avatar.logo && <img src={avatar.logo} alt="Logo" />
                          )}
                        </div>
                      ) : null // or you can use an empty string as you did before
                    }

                    <div
                      className={
                        message.action === "user"
                          ? "bg-gray-500 mb-[1rem] px-6 py-4 text-white rounded-[3rem] w-[80%] z-[100] chat-message"
                          : "bg-blue-500 mb-[1rem] px-6 py-4 text-white rounded-[3rem] w-[80%] z-[100] chat-message"
                      }
                      style={
                        message.action === "user"
                          ? {
                              backgroundColor: `${avatar.characterColor}`,
                              color: `${avatar.textColor}`,
                            }
                          : {
                              backgroundColor: `${avatar.gradientColor}`,
                              color: `${avatar.textColor}`,
                            }
                      }
                    >
                      {message.text.includes("<a") &&
                      message.text.includes("</a>") ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: message.text.replace(
                              /<a/g,
                              '<a style="text-decoration: underline; font-style: italic;color:#8383f5"'
                            ),
                          }}
                        />
                      ) : (
                        message.text
                      )}
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            avatar.chat_history && (
              <div className="chat md:w-[37%] z-[100]">
                {showChat &&
                  chatArray
                    .slice(avatar.chat_history ? 0 : -2)
                    .map((message, index) => (
                      <div
                        key={index}
                        className={
                          message.action === "user"
                            ? "flex justify-end items-center"
                            : "flex justify-start items-center"
                        }
                      >
                        {" "}
                        {showChats ? (
                          <p className="chat-show px-2 py-4 text-white rounded-[3rem] z-[100] ">
                            {avatar.characterName} :{chat}
                          </p>
                        ) : (
                          ""
                        )}
                        {
                          message.action !== "user" ? (
                            <div className="w-[30px] mr-[10px] mb-[1rem]">
                              {avatar.logo && (
                                <img src={avatar.logo} alt="Logo" />
                              )}
                            </div>
                          ) : null // or you can use an empty string as you did before
                        }
                        <div
                          className={
                            message.action === "user"
                              ? "bg-gray-500 mb-[1rem] px-2 py-4 text-white rounded-[3rem] z-[100] chat-message"
                              : "bg-blue-500 mb-[1rem] px-2 py-4 text-white rounded-[3rem] z-[100] chat-message"
                          }
                          style={
                            message.action === "user"
                              ? {
                                  backgroundColor: `${avatar.characterColor}`,
                                  color: `${avatar.textColor}`,
                                }
                              : {
                                  backgroundColor: `${avatar.gradientColor}`,
                                  color: `${avatar.textColor}`,
                                }
                          }
                        >
                          {message.text.includes("<a") &&
                          message.text.includes("</a>") ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: message.text.replace(
                                  /<a/g,
                                  '<a style="text-decoration: underline; font-style: italic;color:#8383f5"'
                                ),
                              }}
                            />
                          ) : (
                            message.text
                          )}
                        </div>
                      </div>
                    ))}
              </div>
            )
          )}
        </div>

        {avatar.animation === "Default" ? (
          <div className="fixed bottom-0 left-0 right-0 p-[16px] z-[100] bg-[#E8E8E8]">
            <div className="flex justify-between items-center">
              <div className="flex flex-col w-[100%]">
                <div className="flex justify-between items-center">
                  {avatar.chat_bar && (
                    <div
                      className="flex justify-between bg-[#260E35] leading-[3rem] rounded-[3rem] px-5 grow"
                      style={{
                        backgroundColor: inputText
                          ? `${avatar.characterColor}`
                          : "grey",
                      }}
                    >
                      <input
                        value={inputText}
                        style={{
                          backgroundColor: inputText
                            ? `${avatar.characterColor}`
                            : "grey",

                          color: `${avatar.textColor}`,
                        }}
                        className="text-white outline-none grow input-field"
                        placeholder={`${avatar.text}`}
                        onChange={(e) => setInputText(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleTextInput(); // Don't forget to invoke the function
                          }
                        }}
                      />
                      <button
                        style={{
                          backgroundColor: inputText
                            ? `${avatar.characterColor}`
                            : "grey",
                          color: `${avatar.textColor}`,
                        }}
                        className="h-[3rem] w-[3rem] text-white"
                        onClick={handleTextInput}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </button>
                    </div>
                  )}{" "}
                  {avatar.key !== "TASIS" && avatar.key !== "TASISDEMO" && (
                    <>
                      <div className="ml-[5px]">
                        <button
                          style={{
                            backgroundColor: `${avatar.characterColor}`,
                            color: `${avatar.textColor}`,
                          }}
                          className="h-[3rem] w-[3rem] bg-[#F1F5F9] rounded-[50%] font-button"
                          onClick={handleToggleMute}
                        >
                          {isMuted ? (
                            <FontAwesomeIcon icon={faVolumeXmark} />
                          ) : (
                            <FontAwesomeIcon icon={faVolumeHigh} />
                          )}
                        </button>
                      </div>
                      <div>
                        <button
                          style={{
                            backgroundColor: `${avatar.characterColor}`,
                            color: `${avatar.textColor}`,
                          }}
                          className="h-[3rem] w-[3rem] bg-[#F1F5F9] rounded-[50%] ml-1 font-button"
                          onClick={handleSpeakClick}
                        >
                          {isRecording ? (
                            <FontAwesomeIcon icon={faRecordVinyl} />
                          ) : (
                            <FontAwesomeIcon icon={faMicrophone} />
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {avatar.characterName !== "Hayat" && (
              <div className="text-[grey] text-center warning-text py-1 px-1">
                {avatar.characterName}
                <span>
                  {" "}
                  can make mistakes. Consider checking important information on
                  the official website.
                </span>
              </div>
            )}
          </div>
        ) : (
          <div className="fixed bottom-0 left-0 right-0 p-[16px] z-[100]">
            <div
              className={`flex items-center ${
                avatar.chat_bar ? "justify-end" : ""
              }`}
            >
              <div className="flex flex-col lg:w-[48%] avatar-input">
                <div className="flex justify-end items-center">
                  {avatar.chat_bar && showChatBar && (
                    <div
                      className="flex justify-between leading-[3rem] rounded-3xl grow padding-x"
                      style={{ backgroundColor: `${avatar.characterColor}` }}
                    >
                      <input
                        value={inputText}
                        className="bg-[#260E35] text-white outline-none grow padding-x rounded-[3rem]"
                        style={{
                          backgroundColor: inputText
                            ? `${avatar.characterColor}`
                            : "grey",

                          color: `${avatar.textColor}`,
                        }}
                        placeholder={`${avatar.text}`}
                        onChange={(e) => setInputText(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleTextInput(); // Don't forget to invoke the function
                          }
                        }}
                        dir={avatar.language === "ar" ? "rtl" : "ltr"}
                      />
                      <button
                        className="h-[3rem] w-[2rem] text-white"
                        style={{
                          // backgroundColor: `${avatar.characterColor}`,
                          color: `${avatar.textColor}`,
                        }}
                        onClick={handleTextInput}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </button>
                    </div>
                  )}
                  {window.screen.width > 425 &&
                    avatar.key !== "nhchayat" &&
                    avatar.key !== "nhc" && (
                      <div className="">
                        <button
                          style={{
                            backgroundColor: `${avatar.characterColor}`,
                            color: `${avatar.textColor}`,
                          }}
                          className="h-[3rem] w-[3rem] bg-[#F1F5F9] rounded-[50%] ml-1 font-button"
                          onClick={handleToggleMute}
                        >
                          {isMuted ? (
                            <FontAwesomeIcon icon={faVolumeXmark} />
                          ) : (
                            <FontAwesomeIcon icon={faVolumeHigh} />
                          )}
                        </button>
                      </div>
                    )}
                  <button
                    className="h-[3rem] w-[3rem] bg-[#F1F5F9] rounded-[50%] ml-1 font-button"
                    style={{
                      backgroundColor: `${avatar.characterColor}`,
                      color: `${avatar.textColor}`,
                    }}
                    onClick={handleSpeakClick}
                  >
                    {isRecording ? (
                      <FontAwesomeIcon icon={faRecordVinyl} />
                    ) : (
                      <FontAwesomeIcon icon={faMicrophone} />
                    )}
                  </button>

                  {avatar.chat_bar &&
                    avatar.key !== "nhchayat" &&
                    avatar.key !== "nhc" && (
                      <button
                        className="h-[3rem] w-[3rem] bg-[#F1F5F9] rounded-[50%] ml-1 font-button"
                        style={{
                          backgroundColor: `${avatar.characterColor}`,
                          color: `${avatar.textColor}`,
                        }}
                        onClick={() => {
                          setShowChat((show) => !show);
                        }}
                      >
                        <FontAwesomeIcon icon={faCommentDots} />
                      </button>
                    )}
                  {avatar.key === "nhchayat" && avatar.key === "nhc" && (
                    <button
                      className="h-[3rem] w-[3rem] bg-[#F1F5F9] rounded-[50%] ml-1 font-button"
                      style={{
                        backgroundColor: `${avatar.characterColor}`,
                        color: `${avatar.textColor}`,
                      }}
                      onClick={() => {
                        setShowChat((show) => !show);
                        setShowChatBar((show) => !show);
                      }}
                    >
                      <FontAwesomeIcon icon={faCommentDots} />
                    </button>
                  )}
                  {window.screen.width < 426 &&
                    avatar.key !== "nhchayat" &&
                    avatar.key !== "nhc" && (
                      <div className="">
                        <button
                          style={{
                            backgroundColor: `${avatar.characterColor}`,
                            color: `${avatar.textColor}`,
                          }}
                          className="h-[3rem] w-[3rem] bg-[#F1F5F9] rounded-[50%] ml-1 font-button"
                          onClick={handleToggleMute}
                        >
                          {isMuted ? (
                            <FontAwesomeIcon icon={faVolumeXmark} />
                          ) : (
                            <FontAwesomeIcon icon={faVolumeHigh} />
                          )}
                        </button>
                      </div>
                    )}
                </div>
              </div>
            </div>
            {avatar.characterName !== "Hayat" && (
              <div className="text-[grey] text-center warning-text py-1 px-1">
                {avatar.characterName}
                <span>
                  {" "}
                  can make mistakes. Consider checking important information on
                  the official website.
                </span>
              </div>
            )}
          </div>
        )}
        <ToastContainer />
      </div>
    );
};

export default Avatar;
